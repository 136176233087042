<template>
    <van-popup v-model="visible" position="bottom">
      <div class="dicts-box">
        <div class="search-box">
          <input class="input" v-model="searchValue" @keyup.enter="onSearch" placeholder="请输入网格/微网格名称" />
          <img class="search-icon" :src="require('@/assets/img/search.png')" alt="">
        </div>
        <div style="height: 60vh; overflow: scroll;">
            <div class="dicts-title">{{title}}</div>
            <!-- 没有子级 -->
            <div class="item-box">
                <div
                    class="check-item"
                    :class="{'isChecked':item.isChecked,'pointer-disable': item.disable}"
                    v-for="item in noChildrenList"
                    :key="item.value"
                    @click="noChildrenChecked(item)"
                >
                    {{item.label}}
                    <img class="checked-icon" v-if="item.isChecked" :src="require('@/assets/img/item-checked.png')" alt="">
                </div>
            </div>
            <!-- 有子级 -->
            <div class="has-children" v-for="item in hasChildrenList" :key="item.value">
                <div class="list-title" v-if="item.children.length > 0" :style=" {color:item.checkbox ? '#4581F8' : ''}">
                    {{item.label}}
                    <van-checkbox
                        :name="item.value"
                        class="title-checked"
                        v-model="item.checkbox"
                        :disabled="item.disable"
                        shape="square"
                        @click="hasChildrenChange(item)"
                    />
                </div>
                <div class="item-box">
                    <div
                        class="check-item"
                        :class="{'isChecked': it.isChecked,'pointer-disable': multiple && it.disable}"
                        v-for="it in item.children"
                        :key="it.value"
                        @click="hasChildrenChecked(item,it)"
                    >
                    {{it.label}}
                    <img class="checked-icon" v-if="it.isChecked" :src="require('@/assets/img/item-checked.png')" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons">
          <van-button class="rest-btn" @click="onCancel">重置</van-button>
          <van-button class="confirm-btn" @click="onConfirm">确定</van-button>
        </div>
      </div>
   </van-popup>
</template>

<script>
import {getMiniGrid} from '@/utils/common'
export default {
    data() {
        return {
            // visible: false,
            checkbox: false,
            noChildrenList: [],
            hasChildrenList: [],
            searchValue: '', // 搜索的值
            items: [], // 选中的id合集
            itemLabels: [], // 选中的label合集
            tempHasList: [], //暂存用于重置
            tempNoHasList: [],//暂存用于重置
        }
    },
    props: {
        /**
         * 标题
         */
        title: {
            type: String,
            required: true
        },
        /**
         * 组织机构
         */
        orgId: {
            type: Number | String,
            required: true
        },
        /**
         * 是否多选
         * 默认为是
         */
        multiple: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        /**
         * 是否是搜索栏使用
         */
        isSearch: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        visible: {
            immediate: true,
            handler(val) {
                if(!val) {
                    this.$emit('update:visible',false)
                }
            }
        },
        items: {
            immediate: true,
            handler(val) {
                if(val.length == 0) {
                    this.noChildrenList.forEach(item => {
                        item.disable = false
                    })
                    this.hasChildrenList.forEach(item => {
                        item.disable = false
                        item.children.forEach(it => {
                            it.disable = false
                        })
                    })
                }
            }
        }
    },
    created() {
        let _this = this
        // 管理网格列表
        getMiniGrid({community:this.orgId}, function (list) {
            // _this.gridList = list
            list.forEach(item => {
                // 给每一项添加isChecked 用于实现选中/未选中样式切换
                item.isChecked = false
                item.disable = false
                if(item.children && item.children.length) {
                    item.checkbox = false
                    item.children.forEach(it => {
                        // 给每一项的子项添加isChecked 用于实现选中/未选中样式切换
                        it.isChecked = false
                        it.disable = false
                    })
                    _this.hasChildrenList.push(item)
                    _this.tempHasList.push(item)
                }else {
                    _this.noChildrenList.push(item)
                    _this.tempNoHasList.push(item)
                }
            })
        })
    },
    methods: {
        // 搜索
        onSearch() {
            this.noChildrenList = JSON.parse(JSON.stringify(this.tempNoHasList))
            this.noChildrenList = this.noChildrenList.filter(item => item.label.indexOf(this.searchValue) > -1)
            this.hasChildrenList = JSON.parse(JSON.stringify(this.tempHasList))
            if(this.searchValue) {
                this.hasChildrenList = this.hasChildrenList.filter(item => {
                    item.children = item.children.filter(it => it.label.indexOf(this.searchValue) > -1)
                    return {...item}
                })
            }
        },
        // 选中没有子级的
        noChildrenChecked(item) {
            if(this.multiple) {
                // 多选
                this.noChildrenList.forEach(ele => {
                    if(ele.value === item.value) {
                        if(ele.isChecked) {
                            // 如果这个项已经被选中了,那就改变为未选中
                            ele.isChecked = false
                            // 从已经被选中的数组Id中除去该项
                            this.items = this.items.filter(i => i.value != ele.value)
                        }else {
                            // 如果这个项未被选中,那就改变为选中
                            ele.isChecked = true
                            // 给选中数组添加该项
                            this.items.push(ele)
                            // this.itemLabels.push(ele)
                        }
                    }
                })
                if(this.items.length && !this.items[0].parameter) {
                    this.hasChildrenList.forEach(item => {
                        item.children.forEach(it=> {
                            it.disable = true
                        })
                    })
                }
            }else {
                this.items = []
                this.noChildrenList.forEach(ele => {
                    if(ele.value === item.value) {
                        if(ele.isChecked) {
                            // 如果这个项已经被选中了,那就改变为未选中
                            ele.isChecked = false
                            // 从已经被选中的数组Id中除去该项
                            this.items = this.items.filter(i => i.value != ele.value)
                        }else {
                            // 如果这个项未被选中,那就改变为选中
                            ele.isChecked = true
                            // 给选中数组添加该项
                            this.items.push(ele)
                        }
                    }else {
                        ele.isChecked = false
                    }
                })
                this.hasChildrenList.forEach(ele => {
                    ele.checkbox = false
                    ele.children.forEach(el => {
                        el.isChecked = false
                    })
                })
            }
        },
        // 选中一级
        hasChildrenChange(item) {
            if(this.multiple) {
                // 多选情况下
                if(item.checkbox) {
                    this.items.push(item)
                    if(this.items.length && !this.items[0].parameter) {
                        this.hasChildrenList.forEach(item => {
                            item.children.forEach(it => {
                                it.disable = true
                            })
                        })
                    }
                }else {
                    this.items = this.items.filter(i => i.value != item.value)
                    this.hasChildrenList.forEach(item => {
                        item.children.forEach(it => {
                            it.disable = false
                        })
                    })
                }
            }else {
                // 单选情况下
                if(item.checkbox) {
                    this.items = [item]
                    this.hasChildrenList.forEach(items => {
                        // 点击另外一个check框判断是否已经有选中的框且不是当前点击的，取消上一个check框的选中状态
                        if(items.checkbox && items.value != item.value) {
                            items.checkbox = false
                        }
                        // 因为是单选，需要把可能选中的子级都取消选中
                        items.children.forEach(it => {
                            it.isChecked = false
                        })
                    })
                    this.noChildrenList.forEach(item => {
                        item.isChecked = false
                    })
                }else {
                    this.hasChildrenList.forEach(items => {
                        if(items.value != item.value) {
                            items.checkbox = false
                        }
                        items.children.forEach(it => {
                            it.isChecked = false
                        })
                    })
                    this.items = []
                }
            }
        },
        // 选中有子级的
        hasChildrenChecked(item,it) {
            if(this.multiple) {
                // 多选情况
                this.hasChildrenList.forEach(ele => {
                    if(ele.value === item.value) {
                        ele.children.forEach(el => {
                            if(el.value === it.value) {
                                if(el.isChecked) {
                                    // ele.checkbox = false
                                    // 如果这个项已经被选中了,那就改变为未选中
                                    el.isChecked = false
                                    // 从已经被选中的数组Id中除去该项
                                    this.items = this.items.filter(i => i.value != el.value)
                                }else {
                                    // 如果这个项未被选中,那就改变为选中
                                    el.isChecked = true
                                    // 给选中数组添加该项
                                    this.items.push(el)
                                }
                            }
                        })
                    }
                })
                if(this.items.length && this.items[0].parameter) {
                    this.noChildrenList.forEach(item => {
                        item.disable = true
                    })
                    this.hasChildrenList.forEach(item => {
                        item.disable = true
                    })
                }
            }else {
                // 单选情况
                this.items = []
                this.noChildrenList.forEach(ele => {
                    ele.isChecked = false
                })
                this.hasChildrenList.forEach(ele => {
                    ele.checkbox = false
                    if(ele.value === item.value) {
                        ele.children.forEach(el => {
                            if(el.value === it.value) {
                                if(el.isChecked) {
                                    // ele.checkbox = false
                                    // 如果这个项已经被选中了,那就改变为未选中
                                    el.isChecked = false
                                    // 从已经被选中的数组Id中除去该项
                                    this.items = this.items.filter(i => i.value != el.value)
                                }else {
                                    // 如果这个项未被选中,那就改变为选中
                                    el.isChecked = true
                                    // 给选中数组添加该项
                                    this.items.push(el)
                                }
                            }else {
                                el.isChecked = false
                            }
                        })
                    }else {
                        ele.children.forEach(el => {
                            el.isChecked = false
                        })
                    }
                })
            }
        },
        // 确定
        onConfirm() {
            this.$emit('change',this.items)
        },
        // 重置
        onCancel() {
            this.searchValue = ''
            this.hasChildrenList.forEach(item => {
                item.checkbox = false
                item.children.forEach(it => {
                    it.isChecked = false
                })
            })
            this.noChildrenList.forEach(item => {
                item.isChecked = false
            })
            this.items = []
            this.noChildrenList = JSON.parse(JSON.stringify(this.tempNoHasList))
            this.hasChildrenList = JSON.parse(JSON.stringify(this.tempHasList))
            if(this.isSearch) {
                this.$emit('cancel')
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.pointer-disable {
    pointer-events: none;
    background-color: #EEE !important;
}
.dicts-box {
    height: 80vh;
    background-color: #FFFFFF;
    border-radius: 16px 16px 0 0;
    padding: 40px 30px;
  .search-box {
    position: relative;
    .input {
        width: 100%;
        height: 64px;
        border: none;
        background: rgba(0,0,0,0.04);
        padding-left: 60px;
        font-size: 26px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
    }
    .search-icon {
      width: 36px;
      height: 36px;
      position: absolute;
      top: 14px;
      left: 12px;
    }
  }
  .dicts-title {
    height: 96px;
    font-size: 32px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #999999;
    line-height: 96px;
    text-align: center;
    // background: #FFFFFF;
  }
  .item-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .check-item {
    position: relative;
    width: 218px;
    height: 100px;
    background: #F5F5F5;
    border-radius: 12px;
    font-size: 30px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #666666;
    // line-height: 74px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .checked-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 32px;
      height: 34px;
    }
  }
  .isChecked {
    color: #4581F8;
    background: #E9EFFD;
  }
  .has-children {
    .list-title {
      height: 96px;
      font-size: 32px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: #333333;
      display: flex;
      align-items: center;
      .title-checked {
        margin-left: 12px;
        border-radius: 4px;
        border: 2px solid rgba(0,0,0,0.15);
      }
    }
  }
  .buttons {
    font-size: 32px;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    margin-top: 56px;
    .rest-btn {
      width: 325px;
      height: 88px;
      background: #FFFFFF;
      border-radius: 8px;
      border: 2px solid #4581F8;
      color: #4581F8;
    }
    .confirm-btn {
      width: 324px;
      height: 88px;
      background: #4581F8;
      border-radius: 8px;
      color: #FFFFFF;
    }
  }
}
</style>
