<template>
    <div class="add-box" @mousedown="down"
        @touchstart.stop="down"
        @mousemove.stop="move"
        @touchmove.stop="move"
        @mouseup.stop="end"
        @touchend.stop="end"
        @click="add"
        ref="fu">
        <div class="add-img">
          <img class="fu-add-icon" :src="imgUrl" alt="">
          <div class="fu-text">新增、添加</div>
        </div>
      </div>
  </template>

  <script>
  export default {
      data() {
          return {
              flags: false, //控制使用
              position: {
                  x: 0,
                  y: 0,
              },
              nx: "",
              ny: "",
              dx: "",
              dy: "",
              xPum: "",
              yPum: "",
              imgUrl: require('@/assets/img/add1.png')
          }
      },
      methods: {
          add(){
              this.$emit('fuAdd')
          },
          down(event) {
              this.flags = true;
              var touch;
              if (event.touches) {
                  touch = event.touches[0];
              } else {
                  touch = event;
              }
              this.position.x = touch.clientX;
              this.position.y = touch.clientY;
              this.dx = this.$refs.fu.offsetLeft;
              this.dy = this.$refs.fu.offsetTop;
          },
          move(event) {
              if (this.flags) {
                  var touch;
                  if (event.touches) {
                      touch = event.touches[0];
                  } else {
                      touch = event;
                  }
                  this.nx = touch.clientX - this.position.x;
                  this.ny = touch.clientY - this.position.y;
                  this.xPum = this.dx + this.nx;
                  this.yPum = this.dy + this.ny;
                  let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
                  let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
                  this.xPum < 0 && (this.xPum = 0);
                  this.yPum < 0 && (this.yPum = 0);
                  this.xPum > width && (this.xPum = width);
                  this.yPum > height && (this.yPum = height);
                  this.$refs.fu.style.left = this.xPum + "px";
                  this.$refs.fu.style.top = this.yPum + "px";
                  //阻止页面的滑动默认事件
                  this.$refs.fu.addEventListener("touchmove",function (event) {
                      if(event.target.className == 'fu-add-icon' || event.target.className == 'fu-text') event.preventDefault();
                  },{passive:false});
              }
          },
          //鼠标释放时候的函数
          end() {
              this.flags = false;
          },
      }
  }
  </script>

  <style>

  </style>
