<template>
  <div class="page-info">
    <van-tabs>
      <van-tab title="网格管理信息">
        <div class="belong" :class="{'isOld40': $isOld}">
          <div>所属网格：<span>{{localGridName}}</span></div>
          <!-- <div class="belong-editor" :class="{'isOld34': $isOld}" @click="editorGrid">{{editor ? '保存' :'编辑'}}</div> -->
        </div>
        <div class="list-item" v-for="item in gridUserList" :key="item.id" @click="goUser(item.id,false)">
          <div class="item-top">
            <div class="top-left" :class="{'isOld32': $isOld}">{{item.postStr}}</div>
            <img class="top-right" :src="require('@/assets/img/mobile.png')" @click.stop="getUserMobile(item.id)" />
          </div>
          <div class="item-down">
            <van-image :src="getImg(item.headImg) || require('@/assets/img/headImg.png')" class="header-img">
              <template slot="loading">
                <img :src="require('@/assets/img/headImg.png')" alt="" class="header-img">
              </template>
              <template slot="error">
                <img :src="require('@/assets/img/headImg.png')" alt="" class="header-img">
              </template>
            </van-image>
            <div class="down-text" :class="{'isOld32': $isOld}">
              <div class="text-name">{{item.name}}</div>
              <div class="text-info">
                {{item.sex == 1 ? '男' : item.sex == 2 ? '女' : '未知'}} {{item.age}} {{item.birthday}}
              </div>
              <div class="text-mobile">{{item.mobile}}</div>
            </div>
            <van-icon size="20" name="arrow" />
          </div>
        </div>
        <van-image v-if="gridUserList.length == 0" :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </van-tab>
      <van-tab title="居民协管信息">
        <div class="belong" :class="{'isOld40': $isOld}">
          <div>所属网格：<span>{{localGridName}}</span></div>
          <!-- <div class="belong-editor" :class="{'isOld34': $isOld}" @click="editorGrid">{{editor ? '保存' :'编辑'}}</div> -->
        </div>
        <div class="list-item" v-for="item in assistList" :key="item.id" @click="goUser(item.id,false)">
          <div class="item-top">
            <div class="top-left" :class="{'isOld32': $isOld}">{{item.postStr}}</div>
            <img class="top-right" :src="require('@/assets/img/mobile.png')" @click.stop="getUserMobile(item.id)" />
          </div>
          <div class="item-down">
            <van-image :src="getImg(item.headImg) || require('@/assets/img/headImg.png')" class="header-img">
              <template slot="loading">
                <img :src="require('@/assets/img/headImg.png')" alt="" class="header-img">
              </template>
              <template slot="error">
                <img :src="require('@/assets/img/headImg.png')" alt="" class="header-img">
              </template>
            </van-image>
            <div class="down-text" :class="{'isOld32': $isOld}">
              <div class="text-name">{{item.name}}</div>
              <div class="text-info">
                {{item.sex == 1 ? '男' : item.sex == 2 ? '女' : '未知'}} {{item.age}} {{item.birthday}}
              </div>
              <div class="text-mobile">{{item.mobile}}</div>
            </div>
            <van-icon size="20" name="arrow" />
          </div>
        </div>
        <van-image v-if="!assistList" :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </van-tab>
    </van-tabs>
    <grid-select
      :visible.sync="gridShow"
      :orgId="$globalData.userInfo.orgId"
      :multiple="false"
      :title="'网格/微网格'"
      :isSearch="false"
      @change="selectGrid"
    ></grid-select>
  </div>
</template>

<script>
import {getImageStream} from '@/utils/index'
import gridSelect from '@/components/gridSelection'
import {getRealMobile} from "@/utils/common"
export default {
  props: ['gridUserList','assistList','houseInfo'],
  components: {
    gridSelect
  },
  data() {
    return {
      buttonLoading: false,
      editor: false,
      grid: '',
      miniGrid: '',
      gridShow: false,
      localGridName: '',
    }
  },
  mounted() {
    this.localGridName = this.houseInfo.gridName
  },
  methods: {
    getImg (path) {
      return  getImageStream(path)
    },
    goUser(userId,editor) {
      this.$router.push({path: '/userRes-info-basic', query: {userId: userId,editor}})
    },
    getUserMobile(id) {
      getRealMobile(id,function(data) {
        window.location.href = `tel://${data.mobile}`
      })
    },
    editorGrid() {
      if(this.editor) {
        this.saveGridAndMiniGrid()
      }else {
        this.gridShow = true
        // this.editor = true
      }
    },
    selectGrid(item) {
      if(item && item.length) {
        let checkedItem = item[0]
        if(checkedItem.depth == 1) {
          this.grid = checkedItem.value
        }else {
          this.grid = checkedItem.parameter.slice(7)
          this.miniGrid = checkedItem.value
        }
        this.localGridName = checkedItem.label
        this.editor = true
      }
      this.gridShow = false
    },
    saveGridAndMiniGrid() {
      this.$toast.loading({duration: 0,message: '保存中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/update'),
        method: 'post',
        data: this.$http.adornData({
          areas: this.houseInfo.areas,
          buildingId: this.houseInfo.buildingId,
          floor: this.houseInfo.floor,
          id: this.houseInfo.id,
          name: this.houseInfo.name,
          remark: this.houseInfo.remark,
          subarea: this.houseInfo.subarea + '',
          unitId: this.houseInfo.unitId,
          useType: this.houseInfo.useType + '',
          doorModel: this.houseInfo.doorModel,
          manyHomeowners: this.houseInfo.manyHomeowners,
          houseCard: this.houseInfo.houseCard,
          collectiveOwnership: this.houseInfo.collectiveOwnership,
          rentalRoomNum: this.houseInfo.rentalRoomNum,
          residentsLimit: this.houseInfo.residentsLimit,
          code: this.houseInfo.code,
          imgUrl: this.houseInfo.imgUrl,
          // labels: this.houseInfo.labels.join(','),
          gridId: this.grid,
          miniGridId: this.miniGrid || 0,
          // extendInfoList: extendInfoList
        })
      }).then(({data}) => {
        this.$toast.clear()
        if(data && data.code === 0) {
          this.$toast.success({
            message: '保存成功',
            duration: 1500,
          })
          setTimeout(() => {
            this.editor = false
          },1500)
        }else {
          this.buttonLoading = false
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>

<style scoped>
.van-tabs >>> .van-tab {
  font-size: 32px;
}
.van-tabs >>> .van-tab--active {
  color: #4581F8;
}
.van-tabs >>> .van-tabs__line {
  background-color: #4581F8;
}
</style>
<style lang="scss" scoped>
.page-info {
  .belong {
    margin: 24px 0 16px;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 96px;
    font-size: 34px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #666666;
    background-color: #FFFFFF;
    span {
      color: #333333;
    }
    .belong-editor {
      width: 116px;
      height: 58px;
      border-radius: 8px;
      border: 2px solid #4581F8;
      font-size: 30px;
      color: #4581F8;
      text-align: center;
      line-height: 58px;
    }
  }
  .list-item {
    background-color: #FFFFFF;
    padding: 0 30px;
    margin-bottom: 16px;
    .item-top {
      height: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      .top-left {
        font-size: 28px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: #666666;
      }
      .top-right {
        width: 40px;
        height: 40px;
      }
    }
    .item-down {
      padding: 32px 0 24px;
      display: flex;
      align-items: center;
      .header-img {
        width: 120px;
        height: 120px;
        margin: 16px;
      }
      .down-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 120px;
        font-size: 28px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: #666666;
        .text-name {
          font-weight: 600;
          color: #333333;
        }
        .text-mobile {
          color: #4581F8;
        }
      }
    }
  }
}
</style>